<template>
  <b-overlay>
    <b-card>
      <b-form>
        <b-row>

          <b-col md="6">
            <b-form-group
              label-for="code"
              :label="$t('PROFILE_CODE_CLIENT_NAVISION')"
            >
              <b-form-input
                id="code"
                v-model="companyInfo.navisionClientCode"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-for="designation"
              :label="$t('PROFILE_DESIGNATION')"
            >
              <b-form-input
                id="designation"
                v-model="companyInfo.name"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-for="adresse1"
              :label="$t('PROFILE_ADRESSE_1')"
            >
              <b-form-input
                id="adresse1"
                v-model="companyInfo.adress"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-for="adresse2"
              :label="$t('PROFILE_ADRESSE_2')"
            >
              <b-form-input
                id="adresse2"
                v-model="companyInfo.adress2"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-for="ville"
              :label="$t('PROFILE_VILLE')"
            >
              <b-form-input
                id="ville"
                v-model="companyInfo.city"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-for="codePostal"
              :label="$t('PROFILE_CODE_POSTAL')"
            >
              <b-form-input
                id="codePostal"
                v-model="companyInfo.postalCode"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label-for="telephone"
              :label="$t('PHONE')"
            >
              <b-form-input
                id="telephone"
                v-model="companyInfo.phone"
                disabled
              />
            </b-form-group>
          </b-col>

        </b-row>
      </b-form>
    </b-card>
    <b-card>
      <CompanyCollaborateur
        :collaborateurs="companyInfo.users"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BOverlay,
} from 'bootstrap-vue'
import CompanyCollaborateur from './collaborateur.vue'

export default {
  components: {
    CompanyCollaborateur,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BOverlay,
  },
  props: {
    companyInfo: {
      type: Object,
      default: () => {
      },
    },
  },

  computed: {
    getInfoCompany() {
      return this.$store.getters['users/getInfoCompany']
    },
  },
}
</script>

<style lang="scss">
</style>
