/* eslint-disable vue/valid-v-model */
<template>
  <b-card>
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            :label="$t('FIRSTNAME')"
            label-for="account-username"
          >
            <b-form-input
              v-model="dataUserProfile.firstName"
              name="firstName"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('LASTNAME')"
            label-for="lastName"
          >
            <b-form-input
              v-model="dataUserProfile.lastName"
              name="lastName"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="dataUserProfile.email"
              :label="$t('PROFILE_EMAIL')"
              name="email"
              placeholder="Email"
              :disabled="true"
            />

          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="numéro à afficher"
              rules="required|min:10"
            >
              <label for="libelle">{{ $t('PHONE') }}  </label>
              <cleave
                id="numero"
                v-model="dataUserProfile.phone"
                type="text"
                name="phone"
                class="form-control"
                :raw="false"
                :options="champsformater.numero"
                maxlength="10"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          v-if="isBetrancourt"
          sm="6"
        >
          <b-form-group
            :label="$t('COMPANY')"
            label-for="company"
          >
            <b-form-input
              v-model="userInfoCompany.company"
              name="company"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('PROFILE_LANGAGE')"
            label-for="account-langue"
          >
            <b-form-select
              v-model="dataUserProfile.language"
              :options="langue"
              name="language"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col sm="12">
          <hr>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('PASSWORD')"
            label-for="password"
          >
            <b-form-input
              v-model="dataUserProfile.password"
              name="password"
              :type="passwordFieldType"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            :label="$t('PASSWORD_CONFIRM')"
            label-for="Password confirm"
          >
            <b-form-input
              v-model="dataUserProfile.passwordconfirm"
              name="passwordconfirm"
              :type="passwordFieldType"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col />
        <b-col
          cols="6"
        >
          <b-button
            v-if="loading"
            variant="primary"
            type="button"
            disabled
            block
          >
            <b-spinner small /> {{ $t('LOADING_BUTTON') }} ...
          </b-button>
          <b-button
            v-else
            variant="primary"
            block
            @click.prevent="sendForm"
          >
            {{ $t('PROFILE_ENREGISTRER') }}
          </b-button>
        </b-col>
        <b-col />
      </b-row>
    </b-form>
  </b-card>

</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormSelect, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import Cleave from 'vue-cleave-component'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.fr'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormSelect,
    Cleave,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: {
    dataUserProfile: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      required,
      loading: false,
      isBetrancourt: false,
      champsformater: {
        numero: {
          phone: true,
          delimiter: '',
          phoneRegionCode: 'FR',
          blocks: [10],
        },
      },
      profileFile: null,
      langue: [
        { value: 'fr', text: 'Français' },
        { value: 'en', text: 'English' },
      ],
      userInfoCompany: {
      },
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.userInfoCompany = JSON.parse(localStorage.getItem('userData'))
    if (this.userInfoCompany.company) {
      this.isBetrancourt = true
    } else {
      this.isBetrancourt = false
    }
  },
  methods: {
    sendForm() {
      this.loading = true
      if (this.dataUserProfile.password === this.dataUserProfile.passwordconfirm) {
        this.$store.dispatch('users/upDateUserData', this.dataUserProfile)
          .then(() => {
            this.$swal({
              title: `${this.$i18n.t('USER_UPDATED_POPUP_HEADER')}`,
              html: null,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      } else {
        this.errors = 'Les deux mots de passe ne sont pas conformes'
        this.loading = false
      }
    },
  },
}
</script>
