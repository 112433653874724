<template>
  <b-card>
    <b-card-text>
      <span class="text-center">{{ $t('LIST_COLLABORATEUR') }}</span>
    </b-card-text>

    <b-list-group>
      <b-list-group-item
        v-for="user in collaborateurs"
        :key="user.id"
        class="d-flex justify-content-between align-items-center"
      >
        <span>{{ user.firstName }} {{ user.lastName }}</span>

        <b-badge
          :variant="user.status? 'success' : 'danger'"
          pill
          class="badge-round"
        >
          <span v-if="user.status">{{ $t('COLLABORATEUR_ACTIVE') }}</span>
          <span v-else>{{ $t('COLLABORATEUR_NON_ACTIVE') }}</span>
        </b-badge>
      </b-list-group-item>
    </b-list-group>

  </b-card>
</template>

<script>
import {
  BListGroup, BListGroupItem, BBadge, BCardText, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BListGroup,
    BCardText,
    BListGroupItem,
    BCard,
  },
  props: {
    collaborateurs: {
      type: Array,
      default: () => {
      },
    },
  },
}
</script>
