<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <b-tab active>

      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('PROFILE_GENERAL') }}</span>
      </template>

      <account-setting-general :data-user-profile="dataUserProfile" />
    </b-tab>

    <b-tab v-if="!isBetrancourt">

      <template #title>
        <feather-icon
          icon="UserPlusIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('PROFILE_ENTITE_JURIDIQUE') }}</span>
      </template>

      <account-setting-entite :company-info="companyInfo" />
    </b-tab>

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './profilGeneral.vue'
import AccountSettingEntite from './profilEntiteJuridique.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingEntite,
  },
  data() {
    return {
      dataUserProfile: {
      },
      companyInfo: { },
      isBetrancourt: true,
    }
  },
  computed: {
    getUserProfile() {
      return this.$store.getters['users/getUserProfile']
    },
  },
  mounted() {
    this.$store.dispatch('users/getUserInfos', JSON.parse(localStorage.getItem('userData')).id)
      .then(r => {
        this.dataUserProfile = r
        if (r.company) {
          this.$store.dispatch('users/getInfoCompany', r)
            .then(res => { this.companyInfo = res })
          this.isBetrancourt = false
        } else {
          this.isBetrancourt = true
        }
      })
  },
}
</script>
